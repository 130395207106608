import axios from "axios";
import BaseEntityModel from "./../general/BaseEntityModel";
import UsersFilter from "./UsersFilter";
import { numberToEn } from "./../../utils/functions";
import {
  PAGE_SIZE,
  STATUS,
  BASE_URL,
  ESTABLISHMENT_TOKEN,
} from "./../../utils/constants";
import UserImg from "./../../assets/images/users.svg";
import i18n from "./../../i18n";
import store from "./../../store";

export default class User extends BaseEntityModel {
  defaultImg = UserImg;
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.userToken = "";
    this.userNameCurrent = "";
    this.userNameAr = "";
    this.userNameEn = "";
    this.userNameUnd = "";
    this.userAddressCurrent = "";
    this.userAddressAr = "";
    this.userAddressEn = "";
    this.userAddressUnd = "";
    this.userPassword = "";
    this.userPhoneCountryCode = "";
    this.userPhoneCountryCodeName = "";
    this.userPhone = "";
    this.userPhoneWithCC = "";
    this.userEmail = "";
    this.userName = "";
    this.userGenderToken = "";
    this.userGenderNameCurrent = "";
    this.userGenderNameAr = "";
    this.userGenderNameEn = "";
    this.userGenderNameUnd = "";
    this.userNationalID = "";
    this.userExpiryDateTimeNationalID = "";
    this.userExpiryDateNationalID = "";
    this.userExpiryTimeNationalID = "";
    this.userTypeNameCurrent = "";
    this.userTypeToken = "";
    this.userTypeNameAr = "";
    this.userTypeNameEn = "";
    this.userTypeNameUnd = "";
    this.userImagePath = "";
    this.userDiscountPercentage = "";
    this.userWebSiteLink = "";
    this.userNotes = "";
    this.countEnquiryQrCodeVCD = 0;
    this.countQrCodeSessionsPurchasesInArchive = 0;
    this.countQrCodeSessionsPurchasesNotArchive = 0;
    this.countQrCodeSessionsPurchasestotal = 0;
    this.userActiveStatus = true;
    this.userArchiveStatus = "";
    this.establishmentToken = ESTABLISHMENT_TOKEN;
    this.establishmentsData = "";
    this.establishmentRoleToken = "";
    this.establishmentRoleData = "";
    this.mediaFile = ""; //
    this.userPhoneCCWithCCName = ""; //
  }
  fillData(data) {
    this.fillDataBaseEntityModelBaseEntityModel(data);
    this.userToken = data.userToken;
    this.userNameCurrent = data.userNameCurrent;
    this.userNameAr = data.userNameAr;
    this.userNameEn = data.userNameEn;
    this.userNameUnd = data.userNameUnd;
    this.userAddressCurrent = data.userAddressCurrent;
    this.userAddressAr = data.userAddressAr;
    this.userAddressEn = data.userAddressEn;
    this.userAddressUnd = data.userAddressUnd;
    this.userPassword = data.userPassword;
    this.userPhoneCountryCode = data.userPhoneCountryCode;
    this.userPhoneCountryCodeName = data.userPhoneCountryCodeName;
    this.userPhone = data.userPhone;
    this.userPhoneWithCC = data.userPhoneWithCC;
    this.userEmail = data.userEmail;
    this.userName = data.userName;
    this.userGenderToken = data.userGenderToken;
    this.userGenderNameCurrent = data.userGenderNameCurrent;
    this.userGenderNameAr = data.userGenderNameAr;
    this.userGenderNameEn = data.userGenderNameEn;
    this.userGenderNameUnd = data.userGenderNameUnd;
    this.userNationalID = data.userNationalID;
    this.userExpiryDateTimeNationalID = data.userExpiryDateTimeNationalID;
    this.userExpiryDateNationalID = data.userExpiryDateNationalID;
    this.userExpiryTimeNationalID = data.userExpiryTimeNationalID;
    this.userTypeNameCurrent = data.userTypeNameCurrent;
    this.userTypeToken = data.userTypeToken;
    this.userTypeNameAr = data.userTypeNameAr;
    this.userTypeNameEn = data.userTypeNameEn;
    this.userTypeNameUnd = data.userTypeNameUnd;
    this.userImagePath = data.userImagePath;
    this.userDiscountPercentage = data.userDiscountPercentage;
    this.userWebSiteLink = data.userWebSiteLink;
    this.userNotes = data.userNotes;
    this.countEnquiryQrCodeVCD = data.countEnquiryQrCodeVCD;
    this.countQrCodeSessionsPurchasesInArchive =
      data.countQrCodeSessionsPurchasesInArchive;
    this.countQrCodeSessionsPurchasesNotArchive =
      data.countQrCodeSessionsPurchasesNotArchive;
    this.countQrCodeSessionsPurchasestotal =
      data.countQrCodeSessionsPurchasestotal;
    this.userActiveStatus = data.userActiveStatus;
    this.userArchiveStatus = data.userArchiveStatus;
    this.establishmentToken = data.establishmentToken;
    this.establishmentsData = data.establishmentsData;
    this.establishmentRoleToken = data.establishmentRoleToken;
    this.establishmentRoleData = data.establishmentRoleData;

    this.userPhoneCCWithCCName =
      data.userPhoneCountryCodeName && data.userPhoneCountryCodeName
        ? `${data.userPhoneCountryCodeName} ${data.userPhoneCountryCode}`
        : "";
  }

  async getAllUsers(language, userAuthorizeToken, pagination, filterData) {
    return await axios.get(
      `/api/Users/GetAllUsers?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${pagination.selfPage}&pageSize=${PAGE_SIZE}&filterStatus=true&paginationStatus=true&userTypeToken=${filterData.userTypeToken}&textSearch=${filterData.textSearch}`
    );
  }

  async getUsersDialog(
    language,
    userAuthorizeToken,
    filterData = new UsersFilter()
  ) {
    let options = [];
    try {
      let response = await axios.get(
        `/api/Users/GetUserDialog?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&userTypeToken=${filterData.userTypeToken}&textSearch=${filterData.textSearch}`
      );
      const itemsData = response.data.itemsData;
      if (response.data.status == STATUS.SUCCESS) {
        for (let item in itemsData) {
          options.push({
            value: itemsData[item]["itemToken"],
            text: itemsData[item]["itemName"],
            image: itemsData[item]["itemImagePath"]
              ? BASE_URL + itemsData[item]["itemImagePath"]
              : UserImg,
          });
        }
      } else if (response.data.status == STATUS.INVALID_TOKEN) {
        store.dispatch("logoutUser");
      } else {
        options.push({
          value: "",
          text: response.data.msg,
          image: UserImg,
        });
      }
    } catch (error) {
      options.push({
        value: "",
        text: i18n.t("errorCatch"),
        image: UserImg,
      });
    }
    return options;
  }

  async getUserDetails(language, userAuthorizeToken) {
    return await axios.get(
      `/api/Users/GetUserDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&userTypeToken=${this.userTypeToken}&token=${this.userToken}`
    );
  }

  async addOrUpdateUser(language, userAuthorizeToken) {
    var formData = new FormData();
    formData.append("mediaFile", this.mediaFile);
    formData.append("language", language);
    formData.append("userAuthorizeToken", userAuthorizeToken);
    formData.append("userToken", this.userToken);
    formData.append("userActiveStatus", this.userActiveStatus);
    formData.append("establishmentRoleToken", this.establishmentRoleToken);
    formData.append("userNameAr", this.userNameAr);
    formData.append("userNameEn", this.userNameEn);
    formData.append("userPassword", this.userPassword);
    formData.append("userPhoneCountryCode", this.userPhoneCountryCode);
    formData.append("userPhoneCountryCodeName", this.userPhoneCountryCodeName);
    formData.append("userPhone", numberToEn(this.userPhone));
    formData.append("userEmail", this.userEmail);
    formData.append("userTypeToken", this.userTypeToken);
    formData.append("userAddressAr", this.userAddressAr);
    formData.append("userAddressEn", this.userAddressEn);
    formData.append("userNationalID", this.userNationalID);
    formData.append(
      "userExpiryDateTimeNationalID",
      this.userExpiryDateNationalID
    );
    formData.append("userGenderToken", this.userGenderToken);
    formData.append("userJobToken", this.userJobToken);

    if (this.userToken == "" || this.userToken == undefined) {
      return await axios.post(`/api/Users/AddUser`, formData);
    } else {
      return await axios.post(`/api/Users/UpdateUser`, formData);
    }
  }

  async archiveUser(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      token: this.userToken,
      userTypeToken: this.userTypeToken,
      archiveStatus: true,
    };

    return await axios.post(`/api/Users/ArchiveUser`, data);
  }
}
