<template>
  <div class="">
    <!-- <PreLoader v-if="isLoading" /> -->

    <form autocomplete="off">
      <div class="row">
        <div
          class="imgView"
          :style="{ 'background-image': `url(${imageSrc})` }"
          alt="Image"
          @click="selectImage"
        ></div>
        <input
          ref="fileInput"
          type="file"
          v-on:change="pickFile"
          class="d-none"
          accept=".jpg, .jpeg, .png"
        />
      </div>

      <!-- <div class="my-card">
        <div class="row">
          <CustomInput
            :className="'col-md-6'"
            :id="'userNameAr'"
            :value="user.userNameAr"
            :title="$t('employeeNameAr')"
            :imgName="'man.svg'"
            v-on:changeValue="user.userNameAr = $event"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'userNameEn'"
            :value="user.userNameEn"
            :title="$t('employeeNameEn')"
            :imgName="'man.svg'"
            v-on:changeValue="user.userNameEn = $event"
          />

          <TelPicker
            :className="'col-md-6'"
            :value="user.userPhoneCCWithCCName"
            v-on:changeCC="user.userPhoneCCWithCCName = $event"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'userPhone'"
            :value="user.userPhone"
            :title="$t('phoneNumber')"
            :imgName="'phone.svg'"
            v-on:changeValue="user.userPhone = $event"
          />

          <CustomInput
            :className="'col-md-6'"
            :id="'userEmail'"
            :value="user.userEmail"
            :title="$t('email')"
            :imgName="'email.svg'"
            v-on:changeValue="user.userEmail = $event"
          />
          <div v-if="submitName == $t('add')" class="form-label-group col-md-6">
            <input
              name="userPassword"
              id="userPassword"
              v-bind:type="[showPassword ? 'text' : 'password']"
              class="form-control password"
              placeholder=" "
              v-model="user.userPassword"
              maxlength="50"
            />
            <label for="userPassword">{{ $t("password") }}</label>
            <img src="@/assets/images/passwordlock.svg" />
            <span class="toggle-password">
              <img
                :src="
                  require('@/assets/images/eye-' +
                    [showPassword ? 'show' : 'hide'] +
                    '.svg')
                "
                @click="showPassword = !showPassword"
              />
            </span>
          </div>

          <CustomSelectBoxWithImg
            :className="'col-md-6'"
            :id="'establishmentRoleToken'"
            :value="user.establishmentRoleToken"
            :options="establishmentRoleTokenOptions"
            v-on:changeValue="user.establishmentRoleToken = $event"
            :title="$t('EstablishmentRoles.select')"
            :imgName="'establishment-role.svg'"
          />
          <CustomSelectBox
            :className="'col-md-6'"
            :id="'userGenderToken'"
            :value="user.userGenderToken"
            :options="userGenderTokenOptions"
            v-on:changeValue="user.userGenderToken = $event"
            :title="$t('selectGender')"
            :imgName="'gender.svg'"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'userAddressAr'"
            :value="user.userAddressAr"
            v-on:changeValue="user.userAddressAr = $event"
            :title="$t('addressAr')"
            :imgName="'address.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'userAddressEn'"
            :value="user.userAddressEn"
            v-on:changeValue="user.userAddressEn = $event"
            :title="$t('addressEn')"
            :imgName="'address.svg'"
          />

          <CustomInput
            :className="'col-md-6'"
            :id="'userNationalID'"
            :value="user.userNationalID"
            :title="$t('nationalNumber')"
            :imgName="'nationalNumber.svg'"
            v-on:changeValue="user.userNationalID = $event"
          />
          <DatePicker
            :className="'col-md-6'"
            :id="'userExpiryDateTimeNationalID'"
            :value="user.userExpiryDateTimeNationalID"
            v-on:changeValue="user.userExpiryDateTimeNationalID = $event"
            :title="$t('nationalNumberExpireDate')"
            :language="language"
          />
        </div>
      </div> -->

      <UserFormFileds :user="user" :submitName="submitName" />
      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="addOrUpdateUser"
        >
          {{ submitName }}
        </button>
        <router-link :to="{ name: PageOfUsers }" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import PreLoader from "@/components/general/PreLoader.vue";
import { BASE_URL } from "@/utils/constants";
import { setDataMultiLang } from "@/utils/functions";
// import CustomInput from "@/components/general/CustomInput.vue";
// import CustomSelectBoxWithImg from "@/components/general/CustomSelectBoxWithImg.vue";
// import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
// import TelPicker from "@/components/general/TelPicker.vue";
// import DatePicker from "@/components/general/DatePicker.vue";
// import TextArea from "@/components/general/TextArea.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import EstablishmentRoles from "./../../models/settings/establishmentRoles/EstablishmentRoles";
import UserFormFileds from "./UserFormFileds.vue";

export default {
  mixins: [createToastMixin],
  components: {
    // PreLoader,
    UserFormFileds,
    // CustomInput,
    // CustomSelectBoxWithImg,
    // CustomSelectBox,
    // TelPicker,
    // DatePicker,
    // TextArea,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken", "constantsListsData"]),
  },
  data() {
    return {
      isLoading: true,
      language: localStorage.getItem("userLanguage") || "ar",
      PageOfUsers: "",
      showPassword: false,
      imageSrc: "",
      userGenderTokenOptions: [],
      userSectorTokenOptions: [],
      userJobTokenOptions: [{ value: null, text: this.$t("selectJob") }],
      establishmentRoles: new EstablishmentRoles(),
      establishmentRoleTokenOptions: [],
    };
  },
  props: ["user", "submitName"],
  methods: {
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
        this.user.mediaFile = file[0];
      }
    },
    async addOrUpdateUser() {
      this.$emit("addOrUpdateUser");
    },
    async getDialogOfEstablishmentRoles() {
      this.isLoading = true;
      try {
        this.establishmentRoleTokenOptions =
          await this.establishmentRoles.establishmentRole.getDialogOfEstablishmentRoles(
            this.language,
            this.userAuthorizeToken
          );
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfGenders() {
      this.isLoading = true;
      this.userGenderTokenOptions = [];
      this.userGenderTokenOptions.push({
        value: "",
        text: this.$t("selectGender"),
      });
      let genders = this.constantsListsData.listGender;
      for (let item in genders) {
        this.userGenderTokenOptions.push({
          value: genders[item]["itemToken"],
          text: setDataMultiLang(
            this.language,
            genders[item]["itemNameAr"],
            genders[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
  },
  watch: {
    // "user.userPhoneCCWithCCName": function (val) {
    //   let cc = val.split(" ");
    //   this.user.userPhoneCountryCode = cc[1];
    //   this.user.userPhoneCountryCodeName = cc[0];
    // },
  },
  async created() {
    this.PageOfUsers = this.$route.meta.pageGet || "";
    this.imageSrc = this.user.userImagePath
      ? BASE_URL + this.user.userImagePath
      : this.user.defaultImg;
    // this.getDialogOfEstablishmentRoles();
    // this.getDialogOfGenders();
  },
};
</script>

<style lang="scss"></style>
